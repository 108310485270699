import 'babel-polyfill'
import 'isomorphic-fetch'
import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Img from 'gatsby-image'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import DividerMid from '../../components/elements/dividerMid'
import PreviewVideo from '../../components/elements/previewVideo'
import FeatureIcon from '../../components/marketing/featureIcon'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import PlatformCallout from '../../components/marketing/platformCallout'
import FeatureQuad from '../../components/marketing/featureQuad'

import NavSubAbout from '../../components/nav/navSubAbout'

// import NavSubAbout from "../../components/nav/navSubAbout"
// import BenefitsBoomerang from "../../components/marketing/benefitsBoomerang"

import { FaCommentDots, FaLayerGroup, FaArrowRight } from 'react-icons/fa'
import { MdPersonPin, MdScreenShare } from 'react-icons/md'

const useCaseData = [
  {
    context: 'Challenge',
    contextColor: 'yellow-1',
    title: 'Driving social revenue',
    description:
      'Companies are under pressure to drive increased revenue from where customers gather: on social media',
    image: 'usecaseRetailSolution',
    iconShow: false,
    iconContext: 'imagespread',
    iconColor: 'blue',
    iconcolor: 'white',
    iconfill: 'white',
    lineClass: 'sky-1',
    anchor: 'images',
  },

  {
    context: 'Challenge',
    contextColor: 'sky-1',

    title: 'Discovering design',
    description:
      'Sometimes, the hardest part is coming up with ideas for posts - and then translating that into a fresh design that resonates with your audience.',
    image: 'usecaseTeamwork',
    iconShow: false,
    iconContext: 'iphone',
    iconColor: 'sky',
    iconcolor: 'white',
    iconfill: 'white',
    lineClass: 'blue-1',
    anchor: 'mobile',
  },

  {
    context: 'Challenge',
    contextColor: 'dkblue-1',
    title: 'On-the-go teams',
    description:
      "For team members who are always on-the-go, it's hard to create compelling content - especially on mobile",
    image: 'usecaseDesignAssets',
    iconShow: false,
    iconContext: 'iphone',
    iconColor: 'sky',
    iconcolor: 'white',
    iconfill: 'white',
    lineClass: 'dkgreen-1',
    anchor: 'mobile',
  },
  // {
  //   title: 'Consistent brand story',
  //   description:
  //     'Telling a compelling and consistent brand story is the way to capture the hearts - and loyalty - of your customers',
  //   image: 'featurePublisherWeights',
  //   iconShow: true,
  //   iconContext: 'imagespread',
  //   iconColor: 'blue',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'sky-1',
  //   anchor: 'images',
  // },
  {
    context: 'Solution',
    contextColor: 'dkgreen-1',

    title: 'Social design',
    description:
      "Storylava helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
    image: 'usecaseRestaurantScreenImage',
    iconShow: false,
    iconContext: 'lightning',
    iconColor: 'dkgreen',
    iconcolor: 'white',
    iconfill: 'white',
    // lineClass: 'dkorange-1',
    anchor: 'share',
  },
]

const featureData = {
  title: 'Benefits',
  titlecolor: 'white-1',
  mode: 'trio',
  bggradient: 'gradient-callout-deepsea',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Brand templates',
          colbgcolor: 'sky-1',
          iconname: 'bullseye',
          description:
            'Ensure on-brand content across teams and affiliates with brand templates',
        },
        {
          title: 'Rapid creation',
          colbgcolor: 'bluegreen-1',
          iconname: 'lightning',
          description:
            'Easily create visual, templated social assets and short videos using drag-and-drop',
        },
        {
          title: 'Drive sales',
          colbgcolor: 'dkblue-1',
          iconname: 'moneycheck',
          description:
            'Drive engagement with content with hyper-relevant industry templates, versus generic ones',
        },
      ],
    },
  ],
}

const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Platform - Visual Selling"
      meta={[
        {
          name: 'description',
          content:
            'Create social images and short videos for sales and marketing teams using Storylava',
        },
      ]}
    />

    <NavSubAbout />
    {/* <div class="gradient-callout-cousteau">
      <div class="container container-page container-about-sub">
        <div class="row">
          <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
            <h1 class="display-2 font-weight-medium white-1">
              Drive sales with hyper-relevant content
            </h1>
            <p class="display-4 white-4">
              Customize images &amp; videos from brand templates to produce
              leads and drive sales
            </p>
          </div>
        </div>
      </div>
    </div> */}

    <div class="callout-home d-flex align-items-center justify-content-center">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <h1 class="text-center font-weight-normal">
              Drive revenue with social channels
            </h1>
          </div>
        </div>
      </div>
      <div class="overlay-sunrise"></div>
      {/* <Img
        css={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '300px',
          zIndex: 10,
        }}
        style={{ position: `absolute` }}
        fluid={props.data.jumbotron.childImageSharp.fluid}
      /> */}
      <StaticImage
        src="../../images/stock/unsplash/linkedin-sales-navigator--AXDunSs-n4-unsplash.jpg"
        alt="Storylava"
        loading="lazy"
        placeholder="blurred"
        layout="fullWidth"
        // width={800}
        // height={300}
        // style={{ position: `absolute` }}
        style={{
          position: `absolute`,
          // top: 0,
          // left: 0,
          // right: 0,
          // bottom: 0,
          inset: 'auto',
          width: '100%',
          height: 400,
          zIndex: 0,
        }}
      />
    </div>

    <div class="border-bottom-1-yellow-1 gradient-callout-deepsea py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="font-weight-light text-center white-1">
                See how Storylava can help you drive consistent engagement with
                customers &mdash; and translate that to revenue
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium blue-1">
            Equip teams with on-brand, customizable content
          </h1>
          <p class="display-4">
            Customize images &amp; videos from brand templates to produce leads
            and drive sales
          </p>
        </div>
      </div>
    </div> */}

    {/* <DividerMid
      class="transparent-1"
      bottomclass="blue-1"
      flip={true}
      lineclass="sky-1"
      lineoffset={4}
      lineflip={true}
      lineclass2="yellow-1"
      lineoffset2={6}
      lineflip2={false}
      // lineclass3="yellow-1"
      // lineoffset3={10}
      // lineflip3={false}
    /> */}

    {/* <div class="gradient-callout-cousteau">
      <div class="container container-page my-0 py-4">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-medium white-1">
              Drive sales with hyper-relevant content
            </h2>
            <p class="display-4 white-1">
              Untether your sales affiliates with a platform to create
              templated, customizable brand content
            </p>
          </div>
        </div>
      </div>
    </div> */}
    {/* <DividerMid class="green-1" line={true} /> */}

    {/* <div class="container container-page"><PlatformCallout /></div> */}
    <AlternatingCallout featureData={useCaseData} />

    {/* <FeatureQuad featureData={featureData} /> */}

    <div class="gradient-callout-seatosky">
      <div class="container container-page my-4 py-4">
        <div class="row d-flex flex-row align-items-center justify-content-center">
          <div class="col-lg-6 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="font-weight-medium white-1 py-3 display-3">
              Discover social design
            </h2>
            <p class="display-4 white-1">
              Learn more about Storylava social design capabilities
            </p>
            <Link
              to="/about/capabilities/"
              class="btn btn-cta-outline mt-2 mb-4"
            >
              See more <FaArrowRight class="btn-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>

    {/* <BenefitsBoomerang
      bgcolor="seafoam-1"
      gradientOverlay={true}
      containerTitle="Benefits for brands"
      data={[
        {
          title: "Reach Gen Z + Millennials",
          body:
            "Overcome short attention spans with short, fast videos and the latest video effects",
        },
        {
          title: "Sharpen your social strategy",
          body:
            "Activate audiences on TikTok, Snapchat, and Instagram beyond just paid ads",
        },
        {
          title: "Rebuild & rebound",
          body:
            "Energize your community to get through crisis periods, and rebound using cause-based marketing",
        },
      ]}
    /> */}
  </Layout>
)

export default About

export const pageQuery = graphql`
  query {
    jumbotron: file(
      relativePath: {
        eq: "stock/unsplash/linkedin-sales-navigator--AXDunSs-n4-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1800, maxHeight: 1000, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
